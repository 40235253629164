import React from 'react';

const SVG = ({
    style = {},
    fill = "#000",
    width = "100%",
    className = "",
    viewBox = "0 0 100 100"
}) => (
    <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <path
        fill={fill}
        d="M71.998,58h-4c-1.104,0-1.999-0.896-1.999-2s0.895-2,1.999-2h4
        c1.104,0,1.999,0.896,1.999,2S73.103,58,71.998,58z M64.142,44.688c-0.781,0.781-2.048,0.781-2.828,0
        c-0.781-0.781-0.781-2.047,0-2.828l2.828-2.828c0.78-0.78,2.047-0.78,2.827,0c0.781,0.781,0.781,2.047,0,2.828L64.142,44.688z
         M61.302,59.999h-4.381c0.682-1.177,1.078-2.54,1.078-3.999c0-4.418-3.581-7.999-7.998-7.999c-4.418,0-8,3.581-8,7.999
        c0,1.459,0.397,2.822,1.08,3.999h-4.382c-0.443-1.252-0.697-2.595-0.697-3.999c0-6.627,5.372-11.998,11.999-11.998
        c6.626,0,11.998,5.371,11.998,11.998C61.999,57.404,61.745,58.747,61.302,59.999z M50.001,40.002c-1.105,0-2-0.896-2-2v-3.999
        c0-1.104,0.895-2,2-2c1.104,0,2,0.896,2,2v3.999C52.001,39.106,51.104,40.002,50.001,40.002z M35.86,44.688l-2.828-2.828
        c-0.781-0.781-0.781-2.047,0-2.828c0.781-0.78,2.047-0.78,2.828,0l2.828,2.828c0.781,0.781,0.781,2.047,0,2.828
        S36.642,45.469,35.86,44.688z M34.003,56c0,1.104-0.896,2-2,2h-4c-1.104,0-2-0.896-2-2s0.896-2,2-2h4
        C33.107,54,34.003,54.896,34.003,56z M50.001,52c0.552,0,1,0.448,1,1v3.828l2.535-2.535c0.391-0.391,1.023-0.391,1.414,0
        s0.391,1.023,0,1.414l-4.242,4.242c-0.391,0.391-1.023,0.391-1.414,0l-4.242-4.242c-0.391-0.391-0.391-1.023,0-1.414
        s1.023-0.391,1.414,0l2.535,2.535V53C49.001,52.448,49.448,52,50.001,52z M40.002,63.999h19.997c1.104,0,2,0.896,2,2
        s-0.896,1.999-2,1.999H40.002c-1.104,0-2-0.895-2-1.999S38.897,63.999,40.002,63.999z"
    />
    </svg>
);

export default SVG;